// Client1
import logo_SR from 'assets/images/logo/logo-dark.svg';
// Client2
import logo_light_JMED from 'assets/images/judicemed/logo_light.png';
import logo_light_sm_JMED from 'assets/images/judicemed/logo_light_sm.png';

import logo_dark_JMED from 'assets/images/judicemed/logo_dark.png';
import logo_dark_sm_JMED from 'assets/images/judicemed/logo_dark1.svg';
import logo_dark_JMED_2 from 'assets/images/judicemed/judicemed_horizontal.svg';
// Client3
import logo_dark_EH from 'assets/images/eh/logo_full_light.svg';
import logo_dark_sm_EH from 'assets/images/eh/logo_sm.svg';
// Client4
import logo_dark_CCARE from 'assets/images/cuidemecare/logo_full.png';
import logo_dark_sm_CCARE from 'assets/images/cuidemecare/logo_small.png';
import logo_dark_CCARE_2 from 'assets/images/cuidemecare/logo_full.png';
// Client5
// Client6
import logo_dark_GH from 'assets/images/guardian/logo_full.png';
import logo_dark_sm_GH from 'assets/images/guardian/logo_small.png';
import logo_dark_GH_2 from 'assets/images/guardian/logo_full.png';
// Client7
import logo_light_ICI from '../assets/images/ici/ICI_horiz_branca.png';
import logo_light_sm_ICI from '../assets/images/ici/ICI_branca.png';
import logo_dark_ICI from '../assets/images/ici/ICI_horizontal.png';
import logo_dark_sm_ICI from '../assets/images/ici/ICI.png';
import logo_dark_ICI_2 from '../assets/images/ici/ICI_preto.png';


export interface ClientConfig {
    id: string;
    session_key: string;
    api: {
        API_URL: string | undefined,
        DOMAIN: string | undefined,
    }
    theme: {
        primaryColor: string;
        secondaryColor: string;
        logo: string;
        logoLightSm: string;
        logoDark: string;
        logoDarkSm: string;
        logoDarkAlt: string;
        companyName: string;
        defaultLang: string;
    };
    features: {
        [key: string]: boolean;
    };
    routes: {
        [key: string]: boolean;
    };
    languages: {
        [key: string]: boolean;
    };
}

const CLIENT_1: ClientConfig = {
    id: 'client1',
    session_key: 'eh-cl1_patient',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_SR,
        logoLightSm: logo_SR,
        logoDarkSm: logo_SR,
        logoDark: logo_SR,
        logoDarkAlt: logo_SR,
        companyName: 'Sanitas Remota',
        defaultLang: 'br'
    },
    features: {
        //
        profile: true,
        alerts: true,
        overview: true,
        detailed: true,
        //
        calendar: true,
        doctors: true,
        systemNotifications: true,
        pathologies: true,
        medicalHistory: true,
        medicines: true,
        register: true,
        termsAndConditions: false,
        alertPanel: true

    },
    routes: {
        dashboard: true,
        myHealth: true,
        apps: true,
        pages: true,
        ui: false,
        transfusions: true
    },
    languages: {
        multi: true,
        spanish: true,
        english: true,
        portuguese: true
    }
};

const CLIENT_2: ClientConfig = {
    id: 'client2',
    session_key: 'eh-cl2_patient',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_light_JMED,
        logoLightSm: logo_light_sm_JMED,
        logoDark: logo_dark_JMED,
        logoDarkSm: logo_dark_sm_JMED,
        logoDarkAlt: logo_dark_JMED_2,
        companyName: 'Judicemed',
        defaultLang: 'br'
    },
    features: {
        //
        profile: true,
        alerts: true,
        overview: true,
        detailed: true,
        //
        calendar: true,
        doctors: true,
        systemNotifications: true,
        pathologies: true,
        medicalHistory: true,
        medicines: true,
        register: false,
        termsAndConditions: true,
        alertPanel: false

    },
    routes: {
        dashboard: true,
        myHealth: true,
        apps: true,
        pages: true,
        ui: false,
        transfusions: false
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};

const CLIENT_3: ClientConfig = {
    id: 'client3',
    session_key: 'eh-cl3_patient',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',    // #dd8986
        secondaryColor: '#00ff00',
        logo: logo_dark_EH,
        logoLightSm: logo_dark_sm_EH,
        logoDark: logo_dark_EH,
        logoDarkSm: logo_dark_sm_EH,
        logoDarkAlt: logo_dark_EH,
        companyName: 'Emmitec.Health',
        defaultLang: 'en'
    },
    features: {
        //
        profile: true,
        alerts: true,
        overview: true,
        detailed: true,
        //
        calendar: true,
        doctors: true,
        systemNotifications: true,
        pathologies: true,
        medicalHistory: true,
        medicines: true,
        termsAndConditions: false,
        alertPanel: true

    },
    routes: {
        dashboard: true,
        myHealth: true,
        apps: true,
        pages: true,
        ui: false,
        transfusions: true
    },
    languages: {
        multi: true,
        spanish: true,
        english: true,
        portuguese: true
    }
};

const CLIENT_4: ClientConfig = {
    id: 'client4',
    session_key: 'eh-cl4_patient',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_dark_CCARE,
        logoLightSm: logo_dark_sm_CCARE,
        logoDark: logo_dark_CCARE,
        logoDarkSm: logo_dark_sm_CCARE,
        logoDarkAlt: logo_dark_CCARE,
        companyName: 'Cuideme.Care',
        defaultLang: 'br'
    },
    features: {
        //
        profile: true,
        alerts: true,
        overview: true,
        detailed: true,
        //
        calendar: true,
        doctors: true,
        systemNotifications: true,
        pathologies: true,
        medicalHistory: true,
        medicines: true,
        register: false,
        termsAndConditions: true,
        alertPanel: false

    },
    routes: {
        dashboard: true,
        myHealth: true,
        apps: true,
        pages: true,
        ui: false,
        transfusions: false
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};

const CLIENT_5: ClientConfig = {
    id: 'client5',
    session_key: 'eh-cl5_patient',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_dark_EH,         // check/change
        logoLightSm: logo_dark_EH,  // check/change
        logoDark: logo_dark_EH,     // check/change
        logoDarkSm: logo_dark_EH,   // check/change
        logoDarkAlt: logo_dark_EH,  // check/change
        companyName: 'Hemobanco',
        defaultLang: 'br'
    },
    features: {
        //
        profile: true,
        alerts: true,
        overview: true,
        detailed: true,
        //
        calendar: true,
        doctors: true,
        systemNotifications: true,
        pathologies: true,
        medicalHistory: true,
        medicines: true,
        register: false,
        termsAndConditions: true,

    },
    routes: {
        dashboard: true,
        myHealth: true,
        apps: true,
        pages: true,
        ui: false,
        transfusions: false
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};

const CLIENT_6: ClientConfig = {
    id: 'client6',
    session_key: 'eh-cl6_patient',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_dark_GH,
        logoLightSm: logo_dark_sm_GH,
        logoDark: logo_dark_GH,
        logoDarkSm: logo_dark_sm_GH,
        logoDarkAlt: logo_dark_GH_2,
        companyName: 'Guardian Health',
        defaultLang: 'br'
    },
    features: {
        //
        profile: true,
        alerts: true,
        overview: true,
        detailed: true,
        //
        calendar: true,
        doctors: true,
        systemNotifications: true,
        pathologies: true,
        medicalHistory: true,
        medicines: true,
        register: false,
        termsAndConditions: true,
        alertPanel: false

    },
    routes: {
        dashboard: true,
        myHealth: true,
        apps: true,
        pages: true,
        ui: false,
        transfusions: false
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};

const CLIENT_7: ClientConfig = {
    id: 'client7',
    session_key: 'eh-cl7_patient',
    api: {
        API_URL: process.env.REACT_APP_API_URL,
        DOMAIN: process.env.REACT_APP_CLIENT_ID
    },
    theme: {
        primaryColor: '#ff0000',
        secondaryColor: '#00ff00',
        logo: logo_light_ICI,
        logoLightSm: logo_light_sm_ICI,
        logoDark: logo_dark_ICI,
        logoDarkSm: logo_dark_sm_ICI,
        logoDarkAlt: logo_dark_ICI_2,
        companyName: 'ICI',
        defaultLang: 'br'
    },
    features: {
        //
        profile: true,
        alerts: true,
        overview: true,
        detailed: true,
        //
        calendar: true,
        doctors: true,
        systemNotifications: true,
        pathologies: true,
        medicalHistory: true,
        medicines: true,
        register: false,
        termsAndConditions: true,
        alertPanel: false

    },
    routes: {
        dashboard: true,
        myHealth: true,
        apps: true,
        pages: true,
        ui: false,
        transfusions: false
    },
    languages: {
        multi: false,
        spanish: false,
        english: false,
        portuguese: true
    }
};
const configs: { [key: string]: ClientConfig } = {
    client1: CLIENT_1,
    client2: CLIENT_2,
    client3: CLIENT_3,
    client4: CLIENT_4,
    client5: CLIENT_5,
    client6: CLIENT_6,
    client7: CLIENT_7,
};

type ClientKey = keyof typeof configs;

const clientKey = (process.env.REACT_APP_CLIENT || 'client1') as ClientKey;
export const activeConfig: ClientConfig = configs[clientKey];
